import {format} from 'date-fns'
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Col, Container, Row} from '~/bootstrap/components/layout'
import {GatsbySEO} from '~/gatsby/components/seo'
import {createReplace} from '../../renderer'
import {linkedItems, validImage} from '../../util'
import {KenticoBodyItem} from '../body-item'
import {KenticoGlobalScriptData} from '../global-script'
import {
  KenticoPageCollectionChild,
  KenticoPageCollectionChildData,
} from '../page-collection-child'
import {KenticoSchemaBlogData} from '../schema'
import {KenticoPageCollectionItemData} from './data'

interface Props {
  page: KenticoPageCollectionItemData
  latestItems: KenticoPageCollectionChildData[]
  globalScripts: KenticoGlobalScriptData
}

const Image = styled.div`
  height: 388px;
`

const TitleContainer = styled(Container)`
  justify-content: center;
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

const ItemsContainer = styled(Container)`
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const MoreArticles = styled.h3`
  font-weight: 500;
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const BlogInfo = styled.div`
  text-transform: uppercase;
`

/** Kentico type data. */
export {KenticoPageCollectionItemData}

/**
 * Render page from Kentico data.
 * @return React component
 */
export const KenticoPageCollectionItem = ({
  page,
  globalScripts,
  latestItems,
}: Props) => {
  let imageData
  if (validImage(page.elements.image)) {
    imageData = page.elements.image.value[0].file.childImageSharp.gatsbyImageData
  }

  const blogInfo = `
    ${page.elements.author.value} |
    ${format(page.elements.publishedDate.value, 'MMMM d, y')}
  `

  const moreArticles = latestItems.map(article => (
    <Col key={article.system.id} lg="4">
      <KenticoPageCollectionChild data={article} />
    </Col>
  ))

  const schema: KenticoSchemaBlogData = useMemo(
    () => ({
      authorName: page.elements.author.value,
      dateModified: page.elements.modifiedDate.value,
      datePublished: page.elements.publishedDate.value,
      description: page.elements.description.value,
      headline: page.elements.title.value,
      imageUrl: page.elements.image.value[0].file.childImageSharp.gatsbyImageData.images.fallback.src,
      logoUrl: page.elements.logo.value[0].file.url,
      system: {
        id: page.system.id,
      },
      url: page.elements.url.value,
    }),
    [page],
  )

  let myGatsbyImageData = imageData as IGatsbyImageData;
  let pluginImage = getImage(myGatsbyImageData);  
  return (
    <>
      <GatsbySEO
        description={page.elements.description.value}
        globalScripts={globalScripts}
        page={page}
        pageScripts={page.elements.scripts}
        replace={createReplace}
        robots={page.elements.robots}
        schema={schema}
        title={page.elements.title.value}
      />
      <KenticoBodyItem data={linkedItems(page.elements.preBody)} />
      <BgImage image={pluginImage}>
        <Image />
      </BgImage>
      <TitleContainer>
        <h1>{page.elements.blogTitle.value}</h1>
        <BlogInfo>{blogInfo}</BlogInfo>
      </TitleContainer>
      <KenticoBodyItem data={linkedItems(page.elements.body)} />
      <ItemsContainer>
        <MoreArticles>More Articles</MoreArticles>
        <Row>{moreArticles}</Row>
      </ItemsContainer>
      <KenticoBodyItem data={linkedItems(page.elements.postBody)} />
    </>
  )
}
