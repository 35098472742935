import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {DateFromString} from '~/common/types'
import {LinkedItems} from '../../util'
import {KenticoBodyItemData} from '../body-item'
import {KenticoFluidImageData} from '../fluid-image'
import {KenticoPageLinkData} from '../page-link'
import {KenticoPageScriptData} from '../page-script'

/** Header. */
export const KenticoPageCollectionItemData = IO.intersection(
  [
    KenticoPageLinkData,
    IO.type({
      elements: IO.type({
        author: IO.type({value: IO.string}),
        blogTitle: IO.type({
          value: IO.string,
        }),
        body: LinkedItems(KenticoBodyItemData),
        canonicalPage: LinkedItems(KenticoPageLinkData),
        description: IO.type({
          value: IO.string,
        }),
        image: IO.type({
          value: IO.array(KenticoFluidImageData),
        }),
        logo: IO.type({
          value: IO.array(
            IO.type({
              file: IO.type({
                url: IO.string,
              }),
            }),
          ),
        }),
        modifiedDate: IO.type({
          value: DateFromString,
        }),
        parent: LinkedItems(KenticoPageLinkData),
        postBody: LinkedItems(KenticoBodyItemData),
        preBody: LinkedItems(KenticoBodyItemData),
        publishedDate: IO.type({
          value: DateFromString,
        }),
        robots: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.string,
            }),
          ),
        }),
        scripts: LinkedItems(KenticoPageScriptData),
        title: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        id: IO.string,
      }),
    }),
  ],
  'KenticoPageCollectionItem',
)

/** Data type. */
export interface KenticoPageCollectionItemData
  extends IO.TypeOf<typeof KenticoPageCollectionItemData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoPageCollectionItem on Node {
    ... on kontent_item_page_collection_item {
      ...KenticoPageLink
      system {
        id
      }
      elements {
        parent {
          nodes: value {
            ...KenticoPageLink
          }
        }
        author {
          value
        }
        publishedDate: published_date {
          value
        }
        modifiedDate: modified_date {
          value
        }
        image {
          value {
            ...KenticoFluidImage
          }
        }
        preBody: pre_post_body__pre_body {
          nodes: value {
            ...KenticoBodyItem
          }
        }
        postBody: pre_post_body__post_body {
          nodes: value {
            ...KenticoBodyItem
          }
        }
        blogTitle: title {
          value
        }
        body: body__body {
          nodes: value {
            ...KenticoBodyItem
          }
        }
        canonicalPage: metadata__canonical_page {
          nodes: value {
            ...KenticoPageLink
          }
        }
        description: metadata__description {
          value
        }
        robots: metadata__robots {
          value {
            codename
          }
        }
        scripts: metadata__scripts {
          nodes: value {
            ...KenticoPageScript
          }
        }
        title: metadata__title {
          value
        }
        logo {
          value {
            file {
              url: publicURL
            }
          }
        }
      }
    }
  }
`
