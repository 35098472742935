import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import React from 'react'
import {KenticoGlobalScriptData} from '~/kentico/components/global-script'
import {KenticoPageCollectionChildData} from '~/kentico/components/page-collection-child'
import {
  KenticoPageCollectionItem,
  KenticoPageCollectionItemData,
} from '~/kentico/components/page-collection-item'
import {LinkedItems, linkedItems} from '~/kentico/util'
import {CookieError} from '~/common/components/cookie-error'
import {GatsbyLayout} from '../components/layout'
import {useDecode} from '../util'

const Props = IO.type({
  data: IO.type({
    globalScripts: KenticoGlobalScriptData,
    latestItems: LinkedItems(KenticoPageCollectionChildData),
    page: KenticoPageCollectionItemData,
  }),
})

/** Query from template. */
export const query = graphql`
  query($id: String!, $limit: Int!, $parentId: String!) {
    page: kontentItemPageCollectionItem(id: {eq: $id}) {
      ...KenticoPageCollectionItem
    }
    latestItems: allKontentItemPageCollectionItem(
      filter: {
        elements: {parent: {value: {elemMatch: {id: {eq: $parentId}}}}}
        id: {ne: $id}
      }
      sort: {fields: [elements___published_date___value], order: DESC}
      limit: $limit
    ) {
      nodes {
        ...KenticoPageCollectionChild
      }
    }
    ...KenticoGlobalScripts
  }
`

const PageCollectionItem = (props: unknown) => {
  const {
    data: {latestItems, ...data},
  } = useDecode(Props, props)
  return (
    <GatsbyLayout contentId={data.page.system.id}>
      <CookieError />
      <KenticoPageCollectionItem
        {...data}
        latestItems={linkedItems(latestItems)}
      />
    </GatsbyLayout>
  )
}

/** Page collection item. */
// eslint-disable-next-line import/no-default-export
export default PageCollectionItem
